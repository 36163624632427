function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

function btnScroll($name){
  $($name).on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });
}

// 以下為點擊後滾動至該區塊 : '.scrollLink'  
function scrollLink($name){
  $($name).on('click', function(e) {
      e.preventDefault();
      var target = $(this).attr("href");
      
      $('html, body').stop().animate({ 
        scrollTop: $(target).offset().top
      }, 1000, function() {
        location.hash = target - 300;
      });
      
      return false;
  });
}

// 以下為主連結在滾動後的effect
function startScroll(){
  var header = $(".start-style");
  $(window).on('scroll',function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 10) {
      header.removeClass('start-style').addClass("scroll-on");
    } else {
      header.removeClass("scroll-on").addClass('start-style');
    }
  });
}

// 取得頁面寬度
function viewport() {
  var e = window,
      a = 'inner';
  if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
}

// Toggle Fun
function toggleFun(item,status){
  $(item).on('click',function() {
    $(status).toggleClass('open');
    return false;
  });
}

$(function() {
  //以下為Menu On Hover
	$('body').on('mouseenter mouseleave','.nav-item',function(e){
      if ($(window).width() > 750) {
        var _d=$(e.target).closest('.nav-item');_d.addClass('show');
        setTimeout(function(){
          _d[_d.is(':hover')?'addClass':'removeClass']('show');
        },1);
      }
  });
  
  // 以下為Gotop
  $(".float-link .link-btn.top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });

  $(window).on("load resize scroll", function (e) {
    var scroll = $(this).scrollTop();
    var wdh = $(window).height();
    if (scroll > 50) {
        $(".float-link").addClass("active");
    } else {
        $(".float-link").removeClass("active");
    }
  });

  // 文字點點點
  function checkEllipsis(name,len){
    $(name).each(function(i){
      if($(this).text().length>len){
          $(this).attr("title",$(this).text());
          let text=$(this).text().substring(0,len-1)+"...";
          $(this).text(text);
      }
    });
  }

  checkEllipsis(".JQellipsis-t20",20);
  checkEllipsis(".JQellipsis",60);
  checkEllipsis(".JQellipsis-blog",80);
  checkEllipsis(".JQellipsis-news",160);

  
  // Toggle
  // 打開搜尋bar
  toggleFun('.toggle-search','.search-formList')

  // 產品分類
  toggleFun('.js-toggle-btn','.js-toggle')

  // ---- 以下為Swiper設定 ---- //
  var bannerSwiper = new Swiper('.bannerSwiper .swiper-container', {
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    keyboard : true,
    virtualTranslate : true,
    on:{
      setTranslate: function(){
        this.$wrapperEl.transition('')
        TweenMax.to(this.$wrapperEl, 1.5, {x:this.translate, ease:Power4.easeOut})
      }
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  var indexSupportSwiper = new Swiper(".index-supportSwiper .mySwiper", {
    speed:300,
    autoplay:{
      delay:3000
    },
    slidesPerView: 3,
    spaceBetween: 20,

    pagination: {
      el: ".index-supportSwiper .swiper-pagination",
      clickable: true,
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 1,
        spaceBetween: 0
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 20,
      }
    }
  });

  var indexHotswiper = new Swiper(".index-hotSwiper .mySwiper", {
    slidesPerView: 2,
    spaceBetween: 16,
    pagination: {
      el: ".index-hotSwiper .swiper-pagination",
      clickable: true,
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 1,
        spaceBetween: 0
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 2,
        spaceBetween: 16,
      }
    }
  });

  var indexNewSwiper = new Swiper(".index-newSwiper .mySwiper", {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: ".index-newSwiper .swiper-pagination",
      clickable: true,
    },
  });

  var downloadSwiper = new Swiper(".download_cataSwiper .mySwiper", {
    slidesPerView: 4,
    spaceBetween: 30,
    grabCursor: true,
    navigation: {
      nextEl: ".download_cataSwiper .swiper-button-next",
      prevEl: ".download_cataSwiper .swiper-button-prev",
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 2,
        spaceBetween: 2
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 4,
        spaceBetween: 10,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 4,
        spaceBetween: 30,
      }
    },
    
    on:{
      click: function(){
        $(this.slides).removeClass('open');
        $(this.clickedSlide).addClass('open');
      },
    }
  });

  // Mobile : 移除所有slide监听事件，所以拖动和click事件失效了。
  var proCataNavSwiper = 'destroy';

  $(window).on('resize', function(){
    SetProCataNavSwiper();
  });
  SetProCataNavSwiper(); 

  function SetProCataNavSwiper(){
    var wh = viewport();

    if(wh.width > 1025){
      if(proCataNavSwiper == "destroy"){
        proCataNavSwiper = new Swiper(".proCataNav-wrap .mySwiper", {
          slidesPerView : 'auto',
          spaceBetween: 15,
          navigation: {
            nextEl: ".proCataNav-wrap .swiper-button-next",
            prevEl: ".proCataNav-wrap .swiper-button-prev",
          },
          breakpoints: { 
            320: {  //当屏幕宽度大于等于320
              slidesPerView: 2,
              spaceBetween: 0
            },
            768: {  //当屏幕宽度大于等于768 
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
            1280: {  //当屏幕宽度大于等于1280
              slidesPerView : 'auto',
              spaceBetween: 15,
            }
          },
        });
      }else{
        console.log('update');
        proCataNavSwiper.update();
      }
    }else {
      if(proCataNavSwiper != "destroy"){
          proCataNavSwiper.destroy(false, true);
          proCataNavSwiper = 'destroy';
      }
    }
  }

  // 產品詳細頁
  // 縮圖
  var Prod_mySwiper = new Swiper(".Prod_mySwiper .swiper", {
    slidesPerView: 4,
    spaceBetween: 5,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: { 
      450: {  //当屏幕宽度大于等于450
        slidesPerView: 3,
        spaceBetween: 3
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
        spaceBetween: 3
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 4,
        spaceBetween: 5,
      }
    }
  });
  // 大圖
  var Prod_mySwiper2 = new Swiper(".Prod_mySwiper2  .swiper", {
    spaceBetween: 10,
    navigation: {
      nextEl: ".Prod_mySwiper .swiper-button-next",
      prevEl: ".Prod_mySwiper .swiper-button-prev",
    },
    thumbs: {
      swiper: Prod_mySwiper,
    },
  });
  
  var timelineSwiper = new Swiper(".timelineSwiper .mySwiper", {
    slidesPerView: 5,
    spaceBetween: 0,
    grabCursor: true,
    navigation: {
      nextEl: ".timelineSwiper .swiper-button-next",
      prevEl: ".timelineSwiper .swiper-button-prev",
    },
    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 2,
        spaceBetween: 0
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 4,
        spaceBetween: 0,
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 5,
        spaceBetween: 0,
      }
    }
  });

  // ---- End ---- //

  //  地址 購買人
  $("#twzipcode").twzipcode();

  // 自定義JS tab(原生))
  const tabsMenu = document.querySelectorAll('.js-tab');
  const tabsPanel = document.querySelectorAll('.js-tabs-panel');

  tabsMenu.forEach(function(data,i){
    data.addEventListener('click',function(){
      // 先定義所有區塊隱藏
      tabsMenu.forEach(function(item){
        item.classList.remove('active')
      });
      tabsPanel.forEach(function(item){
        item.classList.remove('d-block');
      });

      //當前點擊的時候..對應的索引值增加className
      tabsMenu[i].classList.add('active');
      tabsPanel[i].classList.add('d-block')
    });
  })

  //// ---- 以下為call Fun ---- ////
  startScroll();
  btnScroll('.btnScroll');
  scrollLink('.scrollLink' )

});
